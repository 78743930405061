import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import logo from '../../img/logo.png';
import {useEffect, useState} from "react";
import axios from "axios";

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.integra4sys.com.br">
                ITFree by Integra4Sys
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const baseUrl = "http://44.201.90.159:8000";

export default function Album() {

    const [cursos, setCursos] = useState([]);


    const handleSubmit = (event) => {
        event.preventDefault();
        axios.get(baseUrl + "/cursos").then(
            (response) => {
                setCursos(response.data);
            }
        )
        const data = new FormData(event.currentTarget);
        console.log({cursos});
    };

    return (
        <ThemeProvider theme={defaultTheme}>

            <CssBaseline/>
            {/*<AppBar position="relative">*/}
            {/*    <Toolbar>*/}
            {/*        <CameraIcon sx={{mr: 2}}/>*/}
            {/*        <Typography variant="h6" color="inherit" noWrap>*/}
            {/*            Album layout*/}
            {/*        </Typography>*/}
            {/*    </Toolbar>*/}
            {/*</AppBar>*/}
            <main>
                {/* Hero unit */}
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="sm">

                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            <img src={logo} className="App-logo" alt="logo"/>
                        </Typography>
                        <Typography variant="h5" align="center" color="text.secondary" paragraph>
                            Cursos de tecnologias gratu&iacute;tos de todas as áreas
                        </Typography>
                        <Stack
                            sx={{pt: 4}}
                            direction="row"
                            spacing={2}
                            justifyContent="center"
                        >

                        </Stack>

                        <CssBaseline/>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="pesq_curso"
                                label="Curso"
                                name="pesq_curso"
                                autoFocus
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                Pesquisar
                            </Button>
                        </Box>
                    </Container>
                </Box>
                <Container sx={{py: 8}} maxWidth="md">
                    {/* End hero unit */}
                    <Grid container spacing={4}>
                        {cursos.map((card) => (
                            <Grid item key={card} xs={12} sm={6} md={4}>
                                <Card
                                    sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
                                >
                                    <CardMedia
                                        component="div"
                                        sx={{
                                            // 16:9
                                            pt: '56.25%',
                                        }}
                                        image="https://source.unsplash.com/random?wallpapers"
                                    />
                                    <CardContent sx={{flexGrow: 1}}>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            {card.nome}
                                        </Typography>
                                        <Typography>
                                            {card.nome}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small">View</Button>
                                        <Button size="small">Edit</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </main>
            {/* Footer */}
            <Box sx={{bgcolor: 'background.paper', p: 6}} component="footer">
                {/*<Typography variant="h6" align="center" gutterBottom>*/}
                {/*    Footer*/}
                {/*</Typography>*/}
                {/*<Typography*/}
                {/*    variant="subtitle1"*/}
                {/*    align="center"*/}
                {/*    color="text.secondary"*/}
                {/*    component="p"*/}
                {/*>*/}
                {/*    Something here to give the footer a purpose!*/}
                {/*</Typography>*/}
                <Copyright/>
            </Box>
            {/* End footer */}
        </ThemeProvider>
    );
}
