import {React, useEffect, useState} from "react";
import axios from "axios";

const baseUrl = "";

const ServicesAxiosCall = () => {

    const [cursos, setCursos] = useState([]);

    useEffect(() => {
        axios.get(baseUrl + "/cursos").then(
            (response) => {
                setCursos(response.data);
            }
        )
    }, []);

    if (!cursos) {
        return (
            <div>
                <p> Sem resultado!</p>
            </div>
        )
            ;
    }

    return (
        <div>
            {
                cursos.map((curso, index) => (
                    <div key={index}>
                        <span role="img" aria-label={curso.id} id={curso.nome}>{index} : {curso.nome}</span>
                    </div>
                ))
            }
        </div>
        // <div>
        //     <h1>{curso[0].id}</h1>
        //     <p>{curso[0].nome}</p>
        // </div>
    )

}

export default ServicesAxiosCall;