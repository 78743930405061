import logo from '../../img/logo.png';
import './App.css';
import Counter from "../Counter/Counter";
import ServicesAxiosCall from "../ServicesAxiosCall/ServicesAxiosCall";
import Album from "../Album/Album";
import SignIn from "../Album/SignIn";

function App() {
    return (
        // <>
        //     <Album></Album>
        //     <SignIn></SignIn>
        //</>
        <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              Seja bem vindo! Em breve, uma idéia educacional gratuita estará disponível para você!
            </p>
              {/*<Counter></Counter>*/}
              <ServicesAxiosCall></ServicesAxiosCall>

          </header>
        </div>
    );
}

export default App;
